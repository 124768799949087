














































import { defineComponent, onBeforeMount, reactive, toRefs, watchEffect } from '@vue/composition-api'
import { day } from '@/helper'

export default defineComponent({
  setup(props, { root }) {
    const state = reactive({
      couponList: {
        data: [],
        totalCount: 0,
      },
      req: {
        page: 1,
        pageNumber: 1,
        offset: 15,
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: '진행여부', value: 'status' },
        { text: '쿠폰명', value: 'name' },
        { text: '쿠폰 코드', value: 'code' },
        { text: '혜택(생선개수)', value: 'fish' },
        { text: '시작일시', value: 'startDate' },
        { text: '종료일시', value: 'finishDate' },
        { text: '등록자 수', value: 'userCount' },
        { text: null, value: 'action' },
      ],
    })

    const getListCoupon = () => {
      root.$store
        .dispatch('coupon/getListCoupon', {
          offset: state.req.offset,
          pageNumber: state.req.page,
        })
        .then((couponList) => {
          state.couponList = couponList
          state.req.pageNumber = Math.ceil(state.couponList.totalCount / state.req.offset)
        })
    }

    const deleteCoupon = async (id) => {
      if (confirm('쿠폰을 삭제하겠습니까?')) {
        try {
          await root.$store.dispatch('coupon/deleteCoupon', {
            id: Number(id),
          })
        } catch (err) {
          alert(err)
        }
        getListCoupon()
      }
    }

    watchEffect(() => {
      getListCoupon()
      state.req.page = 1
    })

    onBeforeMount(() => {
      getListCoupon()
    })

    return {
      ...toRefs(state),
      getListCoupon,
      deleteCoupon,
      day,
    }
  },
})
